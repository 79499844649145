import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { AppContext } from '../context/global';

class FreshDesk extends Component {
  constructor(props) {
    super(props);
    this.openWidget = this.openWidget.bind(this);
    this.hideAndFillProperties = this.hideAndFillProperties.bind(this);
  }

  componentDidMount() {
    this.hideAndFillProperties();
  }

  openWidget() {
    if (typeof FreshworksWidget === 'undefined') {
      this.loadScript(this.openWidget);
    } else {
      FreshworksWidget('open');
    }
  }

  loadScript(callback) {
    window.fwSettings = {
      widget_id: 16000000214,
    };
    !(function () {
      if ('function' != typeof window.FreshworksWidget) {
        var n = function () {
          n.q.push(arguments);
        };
        (n.q = []), (window.FreshworksWidget = n);
      }
    })();

    const src = 'https://widget.freshworks.com/widgets/16000000214.js';
    if (!document.querySelector(`[src="${src}"]`)) {
      const script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.src = src;
      script.onload = () => {
        this.hideAndFillProperties();
        if (callback) {
          callback();
        }
      };
      document.body.appendChild(script);
    }
  }

  hideAndFillProperties() {
    if (typeof FreshworksWidget === 'undefined') {
      return;
    }

    FreshworksWidget('hide', 'launcher');
    const { user } = this.context;
    const fields = {
      name: !!user && user.fullname ? user.fullname : '',
      email: !!user ? user.email : '',
      custom_fields: {},
    };

    if (this.props.documentId) {
      fields.custom_fields.document_id = this.props.documentId;
    }

    if (this.context.current_plan_name) {
      fields.custom_fields.current_plan_name = this.context.current_plan_name;
    }

    FreshworksWidget('identify', 'ticketForm', fields);
    FreshworksWidget('hide', 'ticketForm', ['custom_fields.document_id', 'custom_fields.plan_name']);
  }

  render() {
    const { i18n, showIcon } = this.props;
    return (
      <a role="button" onClick={this.openWidget}>
        {showIcon && <i className="mdi mdi-help" />} {this.props.label || i18n.t('Contato/Suporte')}
      </a>
    );
  }
}

FreshDesk.propTypes = {
  documentId: PropTypes.number,
  showIcon: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

FreshDesk.defaultProps = {
  showIcon: true,
};

FreshDesk.contextType = AppContext;

export default withNamespaces()(FreshDesk);
